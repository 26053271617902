import { render, staticRenderFns } from "./promo.html?vue&type=template&id=221cdf6a&scoped=true&"
import script from "./promo.vue?vue&type=script&lang=ts&"
export * from "./promo.vue?vue&type=script&lang=ts&"
import style0 from "./promo.scss?vue&type=style&index=0&id=221cdf6a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221cdf6a",
  null
  
)

export default component.exports